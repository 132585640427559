<template>
    <div>
        <!-- tabs -->
        <v-tabs v-if="!firstLevel" v-model="tab" show-arrows height="35">
            <v-tab
                class="text-capitalize "
                :to="{ 
                    name: 'visits-group-by-medical-rep'
                }"
            >
                Daily report
            </v-tab>

            <v-tab
                class="text-capitalize "
                :to="{ 
                    name: 'visits'
                }"
            >
                Visits
            </v-tab>
            <v-tabs-slider color="primary"></v-tabs-slider>
        </v-tabs>
        <v-divider v-if="!$route.query.preview && !firstLevel" class="mb-2"/>

         <!-- child page -->
        <loader v-if="loading"/>
        <router-view v-else/>
    </div>
</template>
<script>
import { firstLevel } from '@/configs/global.config'
export default {
    data() {
        return {
            tab: 0,
            loading: false,
            firstLevel
        }
    }
}
</script>
