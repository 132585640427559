export const maximumUsers = {
    active: false,
    maxUsers: 28
};

export const excelFile = false
//  first level and oubari
export const firstLevel = true

export const roleNameArabic = true

export const controlFieldsAmAndPm = true

export const managementHierarchyRegions = true

export const extraField = true

export const separatingSpecialtiesFromTeams = true
