<template>
    <div>
        <!-- dialog add report -->
        <v-row class="mt-1 mb-2 mx-1">
            <v-col cols="2" class="pa-1">
                <v-autocomplete
                    v-model="classification"
                    label="Classification of notes"
                    :items="classificationReport"
                    item-text="name"
                    item-value="id"
                    clearable
                    outlined
                    dense
                    hide-details
                />
            </v-col>

            <!-- filter -->
            <v-col cols="12" sm="6" md="3" class="pa-1">
                <bee-date-input
                    v-model="fromDate"  
                    :label="$t('inputs.from-date')"
                    input-format-order="DD-MM-YYYY"
                    dense
                    :loading="loading"
                    hide-details
                ></bee-date-input>
            </v-col>

            <v-col>
                <bee-date-input
                    v-model="toDate"
                    :label="$t('inputs.to-date')"
                    input-format-order="DD-MM-YYYY"
                    dense
                    :loading="loading"
                    hide-details
                ></bee-date-input>
            </v-col>

            <!-- string -->
            <v-col cols="2" class="pa-1">
                <v-text-field 
                    v-model="query"
                    label="search"
                    outlined
                    dense
                    hide-details
                />
            </v-col>
            <!-- actions -->
            <v-col cols="auto" class="px-0 pa-1" align-self="center">
                <v-btn
                    color="success"
                    class="px-sm-4 mx-2"
                    :loading="loading"
                    :disabled="loading"
                    @click="getNote();"
                    small
                >
                    <v-icon> mdi-magnify </v-icon>
                    Search
                </v-btn>

                <v-btn
                    color="alert-color white--text"
                    class="px-sm-8"
                    :disabled="loading"
                    @click="clearFilter(); getNote()"
                    small
                >
                    Reset
                </v-btn>
            </v-col>
        </v-row>
        <bee-handy-table
            :items="dailyReports"
            :headers="headers"
            zebra
            dense
            hide-default-footer
            :items-per-page="-1"
            :loading="loading"
        >
            <template v-slot:item.classification="{ item }">
                {{ classificationReport.find(c => c.id === item.classification).name }}
            </template>
        </bee-handy-table>
    </div>
</template>

<script>
import { classificationReport } from '@/helpers/enums'
import { mapState } from 'vuex';
import moment from 'moment';
export default {
    data () {
        return {
            dialog: false,
            reportId: null,
            classificationReport,
            loading: false,
            fromDate: null,
            toDate: null,
            classification: null,
            query: null
        }
    },

    computed: {
        ...mapState({
            medicalReps: state => state.medicalreps.medicalreps,
            dailyReports: state => state.dailyReport.dailyReports
        }),
        headers () {
            return [
                {
                    text: this.$t('headers.report'),
                    name: 'report',
                    value: 'report'
                },

                {
                    text: this.$t('headers.employee'),
                    name: 'employee',
                    value: 'employeeId',
                    setValueExpr: val => this.medicalReps.find(c => c.id === val)?.name
                },

                {
                    text: this.$t('headers.class'),
                    name: 'classification',
                    value: 'classification'
                }
            ]
        }
    },

    methods: {
        moment,

        getNote() {
            this.loading = true
            this.$store.dispatch('dailyReport/fetchByFilter', {
                fromDate: this.fromDate,
                toDate: this.toDate,
                classification: this.classification,
                query: this.query
            }).finally(() => {
                this.loading = false
            })
        },

        clearFilter() {
            this.query = ""
            this.fromDate = null
            this.toDate = null
            this.classification = null
        }
    },

    created() {
        this.loading = true
        if (!this.medicalReps.length) {
            this.$store.dispatch('medicalreps/fetchAll', true).then(() => {
                this.getNote()
            })
        } else {
            this.getNote()
        }
    }
}
</script>
