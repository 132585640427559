<template>
    <div>
        <!-- filter -->
        <v-row>
            <v-col cols="2" class="my-1">
                <v-autocomplete 
                    v-model="teamId"
                    :items="teams"
                    item-text="name"
                    item-value="id"
                    hide-details
                    dense
                    outlined
                    label="Team"
                    :disabled="loading"
                    :loading="loading"
                />
            </v-col>    
            <v-spacer />
            <v-col class="d-flex justify-end" align-self="center">
                <v-btn
                    color="success"
                    class="px-sm-4 mx-2"
                    :loading="loading"
                    :disabled="loading || !teamId"
                    small
                    @click="getTeamById()"
                >
                    <v-icon> mdi-magnify </v-icon>
                    Search
                </v-btn>

            </v-col>
        </v-row>

        <bee-handy-smart-table 
            v-model="tableInputs"
            :headers="headers" 
            :items="items"
            show-add-row
            :editable-rows="!submitLoading"
            :data-table-props="{
                disableSort: true
            }"
            hide-default-footer
            hide-inputs-details
            hide-no-data-area
            dense
            zebra
            :disable-input-fields="submitLoading"
        >
            <!-- specialty -->
            <template v-slot:input.value="{ on, attr }">
                <v-autocomplete 
                    :items="specialties"
                    item-text="name"
                    item-value="id"
                    v-on="on"
                    v-bind="attr"
                />
            </template>

            <template v-slot:input.materialId="{ on, attr }">
                <v-autocomplete 
                    :items="materials"
                    item-text="name"
                    item-value="id"
                    v-on="on"
                    v-bind="attr"
                />
            </template>

            <template v-slot:input.quantity="{ on, attr }">
                <v-text-field 
                    type="number"
                    hide-spin-buttons
                    v-on="on"
                    v-bind="attr"
                />
            </template>

            <!-- action column -->
            <template v-slot:item.actions="{ item, index }">
                <div class="d-flex">        
                    <v-btn
                        icon
                        small
                        color="success"
                        :disabled="submitLoading"
                        @click="addNewRow({ index })"
                    >
                        <v-icon>
                            mdi-plus-circle
                        </v-icon>
                    </v-btn>

                    <v-btn
                        icon
                        small
                        color="alert-color"
                        :disabled="submitLoading"
                        @click="deleteRow({ index })"
                    >
                        <v-icon>
                            mdi-delete-circle
                        </v-icon>
                    </v-btn>
                </div>
            </template>

            <!-- no-input action -->
            <template v-slot:no-input.actions>
                <div class="d-flex justify-center">
                    <v-btn
                        icon
                        small
                        color="success"
                        @click="isEditContact ? editRow() : addRow()"
                    >
                        <v-icon>
                            mdi-check-circle-outline
                        </v-icon>
                    </v-btn>
                </div>
            </template>
        </bee-handy-smart-table>
    </div>
</template>

<script>
import { contactInfoTypes } from '@/helpers/enums'
import { mapState } from 'vuex'
export default {
    data() {
        return {
            tableInputs: {},
            items: [],
            submitLoading: false,
            loading: false,
            contactInfoTypes,
            teamId: null,
            materials: []
        }
    },

    computed: {
        ...mapState({
            teams: state => state.teams.teams,
            specialties: state => state.specialties.specialties
        }),
        headers() {
            return [
                {
                    text: 'Specialty',
                    name: 'value',
                    value: 'value',
                    setValueExpr: val => this.specialties.find(c => c.id === val)?.name,
                },
                {
                    text: 'materials',
                    value: 'materialId',
                    name: 'materialId',
                    writable: !this.loading,
                },
                {
                    text: 'quantity',
                    value: 'quantity',
                    name: 'quantity',
                    writable: !this.loading,
                },
                {
                    text: 'notes',
                    value: 'notes',
                    name: 'notes',
                    writable: !this.loading,
                },
                {
                    text: 'actions',
                    name: 'actions',
                    value: 'actions',
                    align: 'center',
                    noInput: true
                }
            ]
        },
    },

    methods: {
        addRow() {},
        editRow() {},
        deleteRow({ index }) {
            this.items.splice(index, 1);
        },
        addNewRow({ index }) {
            this.items.splice(index, 0, {
                value: this.items[index]?.value
            })
        },

        getTeamById() {
            this.loading = true
            this.items = []
            this.$store.dispatch('teams/fetchById', { id: this.teamId }).then((data) => {
                for (let i = 0; i < data.specialtyIds.length; i++) {
                    this.items.push({
                        value: data.specialtyIds[i]
                    }) 
                }
            }).finally(() => { this.loading = false })
        }
    },

    created() {
        this.loading = true
        Promise.all([
            !this.teams.length ? this.$store.dispatch('teams/fetchAll') : null,
            !this.specialties.length ? this.$store.dispatch('specialties/fetchAll') : null
        ]).finally(() => {
            this.loading = false
        })
    }
}
</script>
