<template>
    <div>
        <!-- visit dialog is declared here to use it as ref -->
        <visit-dialog ref="VisitDialog" @on-save="fetchVisits" :create="date" />
        
        <!-- delete dialog declaered here to use it as ref -->
        <delete-dialog ref="DeleteDialog" @on-delete="fetchVisits">
            <p class="mb-0 text-body-1">
                Are you sure you want to <span class="red--text">DELETE</span> the visit ?
            </p>
        </delete-dialog>

        <!-- filter -->
        <v-row no-gutters class="justify-space-between align-center">
            <!-- month dialog -->
            <v-col md="auto" cols="12">
                <v-dialog
                    ref="dialog"
                    v-model="monthDialog"
                    :return-value.sync="selectedMonth"
                    persistent
                    max-width="300px"
                >
                    <!-- date text -->
                    <template v-slot:activator="{ on, attrs }">
                        <div
                            v-bind="attrs"
                            v-on="on"
                            class="d-flex justify-space-between align-center primary--text"
                        >
                            <div class="d-flex align-center">
                                <v-icon class="me-2" color="primary">mdi-calendar</v-icon>
                                <span class="text-h5 me-2">
                                    {{ moment(date, 'YYYY-MM-DD').format('MMMM')}}
                                </span>
                                <span class="text-subtitle-2 align-self-end">
                                    {{moment(date, 'YYYY-MM-DD').format('YYYY')}}
                                </span>
                            </div>

                            <v-btn
                                v-if="$vuetify.breakpoint.smAndDown"
                                color="primary"
                                small
                                outlined
                                @click="goToTodayDate"
                            >
                                Today
                            </v-btn>
                        </div>
                    </template>

                    <!-- dialog date picker -->
                    <v-date-picker
                        v-model="selectedMonth"
                        full-width
                        picker-date
                        type="month"
                        :max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
                    >
                        <v-spacer></v-spacer>
                        <v-btn
                            text
                            color="primary"
                            @click="monthDialog = false"
                        >
                            cancel
                        </v-btn>
                        <v-btn
                            text
                            color="primary"
                            @click="
                                $refs.dialog.save(selectedMonth);
                                date = moment(selectedMonth, 'YYYY-MM').format('YYYY-MM-DD');
                                fetchVisits();
                            "
                        >
                            OK
                        </v-btn>
                    </v-date-picker>
                </v-dialog>
            </v-col>

            <!-- days picker -->
            <v-col md="auto" cols="12" class="flex-grow-1">
                <v-month-days
                    v-model="date"
                    :month="selectedMonth"
                    :max-width="$vuetify.breakpoint.mdAndUp ? $vuetify.breakpoint.width - 400 : null"
                    :disabled="loading"
                    class="mx-auto"
                    @input="fetchVisits"
                />
            </v-col>

            <!-- today button -->
            <v-col cols="auto" v-if="$vuetify.breakpoint.mdAndUp">
                <v-btn
                    color="primary"
                    small
                    outlined
                    @click="goToTodayDate"
                >
                    Today
                </v-btn>
            </v-col>
        </v-row>

        <div class="d-flex flex-wrap align-center pt-0">
            <span class="text-body-1 me-6">Filter</span>
            <v-chip-group
                v-model="selectedFilter"
                show-arrows
                center-active
                active-class="success"
            >
                <v-chip filter outlined :value="1">
                    Doctor
                </v-chip>
                <v-chip filter outlined :value="2">
                    Pharmacy
                </v-chip>
                <v-chip filter outlined :value="0">
                    Center
                </v-chip>
            </v-chip-group>
            <!-- employees -->
            <v-col cols="4" sm="3">
                <v-autocomplete 
                    v-model="employeeId"
                    :items="medicalReps.filter(c => c.employeeType !== userType.medicalRep)" 
                    item-text="name"
                    item-value="id"
                    label="Employee"
                    outlined
                    dense
                    hide-details
                    clearable
                    @change="fetchVisits"
                />
            </v-col>
            
            <v-spacer/>

            <!-- add visit -->
            <v-btn
                v-if="employeeId === userLogin"
                small
                color="light-blue white--text"
                :disabled="!isDateMatchTodayDate"
                @click="$refs.VisitDialog.addVisit()"
            >
                <v-icon class="me-1" size="22">mdi-plus-circle-outline</v-icon>
                Add visit
            </v-btn>

            <v-divider v-if="excelFile" vertical class="mx-1 my-2" />

            <v-btn
                v-if="excelFile"
                small
                color="green lighten-1 white--text"
                @click="ImportExcel"
                class="mx-1"
                :loading="loadingExcel"
                :disabled="loadingExcel || !employeeId"
            >
                <v-icon> mdi-file-table-outline </v-icon>
                Export Excel
            </v-btn>
        </div>
    
        <!-- table -->
        <bee-handy-table
            :headers="headers"
            :items="visits"
            :loading="loading"
            zebra
            dense
            :data-table-props="{ disableSort: true }"
            :items-per-page="-1"
            hide-default-footer
            fixed-header
            class="mt-1"
        >
            <template v-slot:item.actions="{ item }">
                <!-- edit -->
                <tooltip text="Edit" top>
                    <v-btn
                        icon
                        small
                        color="success"
                        class="me-2"
                        @click="$refs.VisitDialog.editVisit(item.id)"
                    >
                        <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                </tooltip>

                <!-- delete -->
                <tooltip text="Delete" top>
                    <v-btn
                        icon
                        small
                        color="red lighten-1"
                        @click="$refs.DeleteDialog.activeDeleteDialog(item.id, 'visits')"
                    >
                        <v-icon>mdi-delete</v-icon>
                    </v-btn>
                </tooltip>
            </template>
        </bee-handy-table>
    </div>
</template>

<script>
import DeleteDialog from '@/components/DeleteDialog'
import Tooltip from '@/components/Tooltip'
import VisitDialog from '@/components/VisitDialog'
import VMonthDays from '@/components/VMonthDays.vue'
import { mapGetters, mapState } from 'vuex'
import moment from 'moment'
import { userType } from '@/helpers/enums'
import { excelFile } from '@/configs/global.config'
import entities from '@/store/modules/entities'
export default {
	data() {
		return {
            excelFile,
            employeeId: this.$route.query.employeeId ? this.$route.query.employeeId : null,
			monthDialog: false,
			loading: true,
			selectedFilter: null,
			selectedMonth: moment().format('YYYY-MM'),
			date: moment().format('YYYY-MM-DD'),
            userType,
            visits: [],
            loadingExcel: false,
        }
	},

    components: {
		DeleteDialog,
		Tooltip,
		VisitDialog,
		VMonthDays
	},

	computed: {
		filteredVisits() {
			if (this.selectedFilter !== null && this.selectedFilter !== undefined) {
				return this.visits.filter(c => c.entity.entityType === this.selectedFilter);
			} else {
				return this.visits;
			}
		},

		...mapState({
            regions: state => state.regions.regions,
            cities: state => state.cities.cities,
            medicalReps: state => state.medicalreps.medicalreps,
            userLogin: state => state.auth.userData.employeeId,
            specialties: state => state.specialties.specialties,

		}),

        ...mapGetters({
            getMaterialById: 'materials/getMaterialById',
            getSpecialtyById: 'specialties/getSpecialtyById',
        }),
        
        isDateMatchTodayDate() {
            return (this.date === moment().format('YYYY-MM-DD')) || 
                (this.date === moment().subtract(1, 'days').format('YYYY-MM-DD')) || 
                (this.date === moment().subtract(2, 'days').format('YYYY-MM-DD'))
        },

        headers() {
            const headers = [
                {
                    text: 'Entity Name',
                    name: 'entityName',
                    value: 'entityName',
                },
                {
                    text: 'Specialty',
                    name: 'entitySpecialty',
                    value: 'entitySpecialtyId',
                    setValueExpr: val => val.map(c => this.getSpecialtyById(c)?.name).join(', ')
                },
                {
                    text: 'Samples name',
                    name: 'sample',
                    value: 'materialAmounts',
                    setValueExpr: val => val.map(c => this.getMaterialById(c.materialId)?.name).join(', ')
                },
                {
                    text: 'Samples amount',
                    name: 'amount',
                    value: 'materialAmounts',
                    setValueExpr: val => val.map(c => c.amount).join(', ')
                },
                {
                    text: 'Other Samples',
                    name: 'otherSamples',
                    value: 'otherSamples'
                },
                {
                    text: 'Core message',
                    name: 'coreMessage',
                    value: 'coreMessage'
                },
                {
                    text: 'Objections',
                    name: 'objections',
                    value: 'objections'
                },
                {
                    text: 'Duration',
                    name: 'durationInMinutes',
                    value: 'durationInMinutes',
                    noNotAvailableText: true,
                    setValueExpr: val => val + ' min' 
                },
            ];

            if (this.isDateMatchTodayDate) {
                headers.push({
                    text: 'Actions',
                    name: 'actions',
                    value: 'actions',
                    width: 100,
                    sortable: false
                })
            }
            return headers
        }
	},

    methods: {
		moment,
		
		fetchVisits() {
            this.loading = true;
            this.$store.dispatch('visits/fetchByFilter', { 
                IsMedicalRep: false, 
                employeeIds: this.employeeId ? [this.employeeId] : [this.userLogin], 
                fromDate: this.date ? this.date : moment().format('YYYY-MM-DD'),
                toDate: this.date ? this.date : moment().format('YYYY-MM-DD'),
                regionIds: [],
                entityType: this.selectedFilter,
                entityIds: []
            }).then((data) => {
                this.visits = data.data
            }).finally(() => {
                this.loading = false
            })
		},

		editPrevent(message) {
			this.$eventBus.$emit('show-snackbar', message, 'info');
		},

		goToTodayDate() {
			const todayDate = moment().format('YYYY-MM-DD');
			if (this.date !== todayDate) {
                this.selectedMonth = moment().format('YYYY-MM');
                this.date = todayDate;
                this.fetchVisits();
			}
		},
        ImportExcel() {
            this.loadingExcel = true
            this.$store.dispatch('exporter/fetchVisitsExport', { 
                FromDate: this.date, 
                ToDate: this.date, 
                EmployeeId: this.employeeId,
            }).finally(() => {
                this.loadingExcel = false
            })
        },
	},

	created() {
		this.loading = true;
        this.date = this.$route.query.date ? this.$route.query.date : this.moment().format('YYYY-MM-DD')
		Promise.all([
            this.$store.dispatch('visits/fetchByFilter', { 
                IsMedicalRep: false, 
                employeeIds: [this.userLogin], 
                fromDate: this.date ? this.date : moment().format('YYYY-MM-DD'),
                toDate: this.date ? this.date : moment().format('YYYY-MM-DD'),
                regionIds: [],
                entityType: this.selectedFilter,
                entityIds: []
            }).then((data) => {
                this.visits = data.data
            }),
			this.$store.dispatch('materials/fetchAll', {}),
			this.$store.dispatch('materialTransactions/fetchAll', { queryType: userType.supervisor }),
			this.$store.dispatch('materialForms/fetchAll'),
            this.$store.dispatch('cities/fetchCityMine', {}).then((data) => {
                this.$store.dispatch('regions/fetchAllRegions', { cities: data });
            }),
            this.$store.dispatch('materialTransactions/fetchSelfSummary'),
            !this.medicalReps.length ? this.$store.dispatch('medicalreps/fetchAll', true) : null,
            !this.specialties.length ? this.$store.dispatch('specialties/fetchAll') : null,

		]).finally(() => {
            this.loading = false;
        })
	},
    
    beforeRouteLeave (to, from, next) {
        // this. = []
        this.$store.dispatch('entities/clearEntitiesBrief').then(() => {
            next()
        }).catch(() => {
            next(false)
        })
    },

    metaInfo: {
        title: 'Administrative visit',
        titleTemplate: '%s | Octopharma'
    }
}
</script>
