import * as types from '../mutation-types'
export default {
    namespaced: true,

    state: {
        dailyReports: []
    },
   
    actions: {
        fetchByFilter({ commit }, { fromDate, toDate, classification, query }) {
            return window.axios.get('/dailyReport/Filters', { 
                params: { 
                    fromDate, 
                    toDate, 
                    classification, 
                    query
                } 
            }).then(({ data }) => {
                commit(types.STORE_DAILY_REPORT, data)
                return data
            })
        }
    },
    
    mutations: {
        [types.STORE_DAILY_REPORT](state, dailyReports) {
            state.dailyReports = dailyReports
        }
    },

    getters: {}
}