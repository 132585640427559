import * as types from '@/store/mutation-types'
export default {
    
    namespaced: true,

    state: {
        provinces: []
    },

    actions: {
        fetchAll({ commit }) {
            return window.axios.get('/provinces').then(({ data }) => {
                commit(types.STORE_PROVINCE, data)
                return data
            })
        },

        create ({ commit }, { name }) {
            return window.axios.post('/provinces', { name }).then((data) => {
                return data
            })
        },

        update ({ commit }, { id, name }) {
            return window.axios.put(`/provinces/${id}`, { name }).then((data) => {
                return data
            })
        },

        delete ({ commit }, { id }) {
            return window.axios.delete(`/provinces/${id}`).then((data) => {
                return data
            })
        }
    },

    mutations: {
        [types.STORE_PROVINCE](state, provinces) {
            state.provinces = provinces
        }
    },

    getters: {
        getProvinceById: state => id => state.provinces.find(c => c.id === id)
    }
}