export default {
    home: 'Home',
    'medRep-zone': 'Med.Rep Zone',
    visits: 'Visits',
    'med-rep-plan': 'Med. Rep plan',
    'day-off-requests': 'Day off requests',
    'day-off-request': 'Day off request',
    reports: 'Reports',
    'med-rep-mark': 'Med.Rep. Mark',
    exams: 'Exams',
    salaries: 'Salaries',
    inventory: 'Inventory',
    'delivery-samples': 'Deliver items',
    'create-samples': 'Create samples',
    statement: 'Statement',
    'products-statement': 'Products statement',
    management: 'Management',
    staff: 'Staff',
    customers: 'Customers',
    'managing-roles': 'Managing roles',
    'external-entities': 'External entities',
    products: 'Products',
    'regions-management': 'Regions management',
    'administrative-zone': 'Administrative Zone',
    'my-visits': 'My visits',
    'report': 'Report',
    'plan': 'Plan',
    logout: 'Logout',

    'add-day-off-request': 'Add day off request',
    'minimal-interface': 'Material stock limit',
    'warehouses-statistics': 'Warehouse statistics',
    'daily-report': 'Daily notes'
}