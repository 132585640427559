<template>
    <div class="d-flex flex-column justify-space-between align-items-end ">
        <v-layout align-center justify-center :style="{ height: `${$vuetify.breakpoint.height - 40}px` }">
            <v-flex xs12 sm8 md4>
                <div class="text-center">
                    <img :src="logo" width="250" class="mb-4" /> 
                </div>
                <v-form @submit.prevent="login" ref="form">
                    <v-card class="elevation-12">
                        <v-toolbar dark color="primary">
                            <v-toolbar-title>{{$t('Login.login')}}</v-toolbar-title>
                        </v-toolbar>

                        <v-card-text>
                            <v-text-field
                                prepend-icon="mdi-account"
                                name="login"
                                :label="$t('inputs.username')"
                                type="text"
                                v-model="username"
                                :disabled="loading"
                                :rules="rules.required"
                                ></v-text-field>
                                <v-text-field
                                id="Password"
                                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                                prepend-icon="mdi-lock"
                                @click:append="showPassword = !showPassword"
                                name="password"
                                :label="$t('inputs.password')"
                                :type="showPassword ? 'text' : 'password'"
                                v-model="password"
                                :disabled="loading"
                                :rules="rules.required"
                            ></v-text-field>
                        </v-card-text>

                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                                color="primary"
                                type="submit"
                                :disabled="loading"
                                :loading="loading"
                            >
                                {{$t('Login.login')}}
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-form>
                <v-alert
                :value="error != null"
                v-if="!loading && error"
                type="error"
                class="mt-3"
                transition="scale-transition"
                >{{ error }}</v-alert>
            </v-flex>
        </v-layout>
        <v-footer
        	height="30"
        	fixed
        	color="transparent"
        >
            <v-spacer/>
            <p class="text-body-2 font-weight-bold">
                Octopharma | v{{version}} | Powered By 
                <a href="https://beetronix.com" target="_blank" class="text-decoration-none">
                    Beetronix
                </a>
            </p>
            <v-spacer/>
        </v-footer>
    </div>
</template>

<script>
import { VERSION } from '@/constants';
import rules from '../../validation rules'
import { logo } from '@/configs/brand.config';

export default {
    data: () => ({
        // configs
        logo,

        // 
        username: null,
        password: null,
        loading: false,

        error: null,
        snackbar: false,
        message: '',

        version: VERSION,
        showPassword: false,

        rules
    }),

    methods: {
        login() {
            if (this.$refs.form.validate()){
                this.loading = true;
                this.error = null;

                this.$store.dispatch('auth/login', {
                    username: this.username,
                    password: this.password,
                }).catch(error => {
                    if (error.name === 42){
                        this.error = error.message;
                    }
                    else {
                        this.error = error.response.data.message
                    }
                }).then(() => {
                    // this.$store.dispatch('cities/fetchCityMine', {}).then(() => {
                    this.$router.replace({ name: 'home' })
                    // })
                }).finally(() => {
                    this.loading = false
                });
            }
        }
    },

    metaInfo: {
        title: 'Login',
        titleTemplate: '%s | Octopharma'
    }
}
</script>
<style>
</style>