<template>
    <div>
        <div class="d-flex flex-wrap align-center mb-sm-5">
            <h2 class="text-h5 primary--text text-capitalize">Sales Statistics</h2>

            <v-spacer/>

            <!-- View chart -->
            <v-btn
                small
                color="primary"
                :to="{ name: 'statistics-chart', params: { type: 'sales' } }"
            >
                <v-icon small class="me-2">mdi-chart-bar</v-icon>
                View chart
            </v-btn>
        </div>
        
         <!-- filter -->
        <v-row class="mx-1">
            <!-- material name -->
            <v-col md="3" sm="6" cols="12" class="pa-1">
                <v-autocomplete
                    v-model="materialIds"
                    :items="materials"
                    :item-text="getFullMaterialName"
                    item-value="id"
                    :return-object="false"
                    label="Material Name"
                    dense
                    hide-details
                    multiple
                    clearable
                    :loading="filterLoading"
                    :disabled="loading"
                    outlined
                >
                    <template v-slot:selection="{ item, index }">
                        <span
                            v-if="index === 0"
                            class="me-1"
                        >
                            {{ item.name }}
                        </span>
                        <span
                            v-if="index === 1"
                            class="grey--text text-caption"
                        >
                            (+{{ materialIds.length - 1 }} others)
                        </span>
                    </template>
                </v-autocomplete>
            </v-col>

            <!-- city name -->
            <v-col md="2" sm="6" cols="12" class="pa-1">
                <v-autocomplete
                    v-model="cityIds"
                    :items="cities"
                    item-text="name"
                    item-value="id"
                    :return-object="false"
                    label="City"
                    dense
                    hide-details
                    multiple
                    clearable
                    :loading="filterLoading"
                    :disabled="loading"
                    outlined
                >
                    <template v-slot:selection="{ item, index }">
                        <span
                            v-if="index === 0"
                            class="me-1"
                        >
                            {{ item.name }}
                        </span>
                        <span
                            v-if="index === 1"
                            class="grey--text text-caption"
                        >
                            (+{{ cityIds.length - 1 }} others)
                        </span>
                    </template>
                </v-autocomplete>
            </v-col>

            <!-- warehouses -->
            <v-col md="2" cols="12" class="pa-1">
                <v-autocomplete
                    v-model="warehouseIds"
                    :items="warehouses"
                    item-text="name"
                    item-value="id"
                    :return-object="false"
                    label="Warehouse"
                    dense
                    hide-details
                    multiple
                    clearable
                    :loading="filterLoading" 
                    :disabled="loading"
                    outlined
                >
                    <template v-slot:selection="{ item, index }">
                        <span
                            v-if="index === 0"
                            class="me-1"
                        >
                            {{ item.name }}
                        </span>
                        <span
                            v-if="index === 1"
                            class="grey--text text-caption"
                        >
                            (+{{ warehouseIds.length - 1 }} others)
                        </span>
                    </template>
                </v-autocomplete>
            </v-col>
        </v-row>

        <v-row class="mx-1 mb-1">
            <!-- start date -->
            <v-col md="3" sm="6" cols="12" class="pa-1" align-self="center">
                <bee-date-picker
                    v-model="menuOfStartDate"
                    input-label="Start date"
                    elevation="2"
                    menu-picker
                    dense
                    hide-details
                    :input-props="{
                        outlined: true,
                        dense: true
                    }"
                    :max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
                    min="1940-01-01"
                ></bee-date-picker>
            </v-col>

            <!-- end date -->
            <v-col md="3" sm="6" cols="12" class="pa-1" align-self="center">
                <bee-date-picker
                    v-model="menuOfEndDate"
                    input-label="End date"
                    elevation="2"
                    menu-picker
                    dense
                    hide-details
                    :input-props="{
                        outlined: true,
                        dense: true
                    }"
                    :max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
                    min="1940-01-01"
                ></bee-date-picker>
            </v-col>

            <v-spacer />
            <v-col class="pa-1 d-flex justify-end" align-self="center">
                <v-btn
                    color="success"
                    class="px-sm-4 mx-2"
                    :loading="loading"
                    :disabled="loading || isAllFieldsEmpty"
                    @click="isFiltered = true; filter();"
                    small
                >
                    <v-icon> mdi-magnify </v-icon>
                    Search
                </v-btn>
                <v-btn
                    class="px-sm-8"
                    @click="clearFilter(); filter();"
                    :disabled="!isFiltered || loading"
                    color="alert-color white--text"
                    small
                >
                    Reset
                </v-btn>
            </v-col>
        </v-row>

        <!-- table -->
        <bee-handy-table
            :headers="headers"
            :items="!loading ? extendedStatistics : []"
            :loading="loading"
            dense
            zebra
            pagination-on-scroll
            fixed-header
            :height="$vuetify.breakpoint.height - 188"
        ></bee-handy-table>
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
    data(){
        return {
            // start date
            menuOfStartDate: null,

            // end date
            menuOfEndDate: null,

            // options
            options: null,
            totalStatistics: 3,
            
            // helpers
            loading: false,
            filterLoading: false,
            isFiltered: false,

            // filter data
            materialIds: [],
            cityIds: [],
            warehouseIds: [],
            startDate: null,
            endDate: null,
        }
    },

    methods:{
        getFullMaterialName(material) {
            var details = [
                this.getMaterialFormById(material.materialFormId).name,
                material.dose,
                material.strength
            ];

            return `${material.name} (${details.filter(c => c != null).join(' ')})`;
        },

        // filter funcitons
        filter(){
            this.loading = true;
            this.$store.dispatch('statistics/fetchAll', {
                materialIds: this.materialIds,
                cityIds: this.cityIds,
                warehouseIds: this.warehouseIds,
                fromDate: this.startDate,
                toDate: this.endDate,
                returnSums: true
            })
            .finally(() => {
                this.loading = false;
            });
        },

        clearFilter(){
            this.materialIds = [];
            this.cityIds = [];
            this.warehouseIds = [];
            this.startDate = null;
            this.endDate = null;

            this.isFiltered = false;
        },
    },

    computed:{
        isAllFieldsEmpty(){
            return (
                this.materialIds.length === 0 && this.cityIds.length === 0 && this.warehouseIds.length === 0 && !this.startDate && !this.endDate
            );
        },

        extendedStatistics() {
            const sales = [
                ...this.statistics
            ];

            let i = 0;
            for (const entry of sales) {
                entry.id = i++;
            }

            return sales;
        },

        ...mapState({
            cities: state => state.cities.cities,
            materials: state => state.materials.materials,
            warehouses: state => state.warehouses.warehouses,
            sales: state => state.sales.sales,
            statistics: state => state.statistics.statistics,
        }),

        ...mapGetters({
            getCityById: 'cities/getCityById',
            getMaterialById: 'materials/getMaterialById',
            getMaterialFormById: 'materialForms/getMaterialFormById',
        }),

        headers() {
            return [
                { 
                    text:'Material', 
                    align:'start', 
                    value:'materialId', 
                    setValueExpr: val => this.getFullMaterialName(this.getMaterialById(val)),
                    sortable: false,  
                    class:'text-caption font-weight-bold',
                },
                { 
                    text:'City', 
                    align:'start',
                    value:'cityId', 
                    setValueExpr: val => this.getCityById(val).name,
                    width: 200,
                    class:'text-caption font-weight-bold',
                },
                { 
                    text:'Sales Quantity', 
                    align:'start', 
                    value:'quantity', 
                    setValueExpr: val => val + ' pieces',
                    width: 200,
                    class:'text-caption font-weight-bold',
                },
            ]
        }
    },

    mounted(){
        this.loading = true;
        this.filterLoading = true;

        Promise.all([
            this.$store.dispatch('cities/fetchCityMine', {}),
            this.$store.dispatch('materials/fetchAll'),
            this.$store.dispatch('materialForms/fetchAll'),
            this.$store.dispatch('warehouses/fetchAll'),
            this.$store.dispatch('statistics/fetchAll', {
                materialIds: this.materialIds,
                cityIds: this.cityIds,
                warehouseIds: this.warehouseIds,
                fromDate: this.startDate,
                toDate: this.endDate,
                returnSums: true
            })
        ])
        .finally(() => {
            this.loading = false;
            this.filterLoading = false;
        })
    },

    metaInfo: {
        title: 'Statistics',
        titleTemplate: '%s | Octopharma'
    },
}
</script>

<style>

</style>