import * as types from '../mutation-types'

export default {
    namespaced: true,

    state:{
        visitsAchievements: [],
        workloadVisits: [],
        visitCount: [],
        staff: { lastEmployees: [] },
        teams: [],
        staffRequests: [],
        materialsStat: {
            materialState: [],
            topMaterial: [],
            lessMaterial: [],
            deliverPercent: 0
        },
        entitiesStat: [],
        entitiesVisits: []
    },

    actions: {
        fetchVisitsAchievements ({ commit }) {
            return window.axios.get('/statistics/VisitsAchivements').then(({ data }) => {
                commit(types.STORE_VISITS_ACHIEVEMENTS, data)
                return data
            })  
        },

        fetchWorkloadVisits ({ commit }, { fromDate, toDate, cityId, provinceId }) {
            return window.axios.get('/statistics/workloadVisits', { params: { fromDate, toDate, cityId, provinceId } }).then(({ data }) => {
                commit(types.STORE_WORKLOAD_VISITS, data)
                return data
            })  
        },

        fetchVisitsCount ({ commit }, { fromDate, toDate }) {
            return window.axios.get('/statistics/visitsCount', { params: { fromDate, toDate } }).then(({ data }) => {
                commit(types.STORE_VISITS_COUNT, data)
                return data
            })  
        },

        fetchStaff ({ commit }) {
            return window.axios.get('/statistics/staff').then(({ data }) => {
                commit(types.STORE_STAFF, data)
                return data
            })  
        },

        fetchTeams ({ commit }) {
            return window.axios.get('/statistics/teams').then(({ data }) => {
                commit(types.STORE_TEAMS_STA, data)
                return data
            })  
        },

        fetchStaffRequests ({ commit }) {
            return window.axios.get('/statistics/StaffRequests').then(({ data }) => {
                commit(types.STORE_STAFF_REQUESTS, data)
                return data
            })  
        },

        fetchMaterials ({ commit }, { fromDate, toDate }) {
            return window.axios.get('/statistics/Materials', { params: { fromDate, toDate } }).then(({ data }) => {
                commit(types.STORE_MATERIALS_STAT, data)
                return data
            })  
        },

        fetchEntities ({ commit }) {
            return window.axios.get('/statistics/Entities').then(({ data }) => {
                commit(types.STORE_ENTITIES_STAT, data)
                return data
            })  
        },

        fetchEntitiesVisits ({ commit }, { fromDate, toDate, cityId }) {
            return window.axios.get(`/statistics/EntitiesVisits/${cityId}`, { params: { fromDate, toDate } }).then(({ data }) => {
                commit(types.STORE_ENTITIES_VISITS, data)
                return data
            })
        }
    },

    mutations: {
        [types.STORE_VISITS_ACHIEVEMENTS](state, visitsAchievements) {
            state.visitsAchievements = visitsAchievements
        },
        [types.STORE_WORKLOAD_VISITS](state, workloadVisits) {
            state.workloadVisits = workloadVisits
        },
        [types.STORE_VISITS_COUNT](state, visitCount) {
            state.visitCount = visitCount
        },
        [types.STORE_STAFF](state, staff) {
            state.staff = staff
        },
        [types.STORE_TEAMS_STA](state, teams) {
            state.teams = teams
        },
        [types.STORE_STAFF_REQUESTS](state, staffRequests) {
            state.staffRequests = staffRequests
        },
        [types.STORE_MATERIALS_STAT](state, materialsStat) {
            state.materialsStat = materialsStat
        },
        [types.STORE_ENTITIES_STAT](state, entitiesStat) {
            state.entitiesStat = entitiesStat
        },
        [types.STORE_ENTITIES_VISITS](state, entitiesVisits) {
            state.entitiesVisits = entitiesVisits
        }
    }
}