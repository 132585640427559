<template>
	<div class="">
		<!-- filter -->
		<v-row class="mt-1 mx-1 mb-1">
			<!-- city region filter -->
			<!-- when customer required -->
			<v-col lg="4" md="5" sm="7" cols="12" class="px-1 pb-0">
				<v-row class="pa-3 pa-3 d-flex justify-space-between">
					<!-- cities -->
					<v-col cols="5" class="pa-0 pe-1 d-flex">
						<v-icon class="me-2" size="28"> mdi-map-marker-multiple </v-icon>
						<v-autocomplete
							v-model="cityId"
							:items="cities"
							item-text="name"
							item-value="id"
							label="City"
							dense
							hide-details
							append-icon
							@change="setRegions(); searchCityId = null; typeAccount = null"
							:disabled="loading"
							outlined
							multiple
							:search-input.sync="searchCityId"
						>
							<template v-slot:prepend-item>
								<v-list-item
									ripple
									@click="toggleCities"
								>
									<v-list-item-action>
										<v-icon>
											{{ iconCities }}
										</v-icon>
									</v-list-item-action>
									
									<v-list-item-content>
										<v-list-item-title>
											Select All
										</v-list-item-title>
									</v-list-item-content>
								</v-list-item>
								<v-divider class="mt-2"></v-divider>
							</template>

							<template v-slot:selection="{ item, index }">
								<span v-if="index === 0" class="me-1">{{ item.name }}</span>
								<span
									v-if="index === 1"
									class="grey--text text-caption"
								>
									(+{{ cityId.length - 1 }})
								</span>
							</template>   
						</v-autocomplete>
					</v-col>

					<!-- region -->
					<v-col cols="7" class="pa-0">
						<v-autocomplete
							v-model="regionIds"
							:items="regionsAll.filter(c => cityId.includes(c.cityId))"
							item-text="name"
							item-value="id"
							:label="managementHierarchyRegions ? 'Areas' : 'Regions'"
							dense
							multiple
							clearable
							hide-details
							:loading="regionsLoading"
							:disabled="loading || regionsLoading || cityId === []"
							outlined
							:search-input.sync="searchRegionId"
							@change="searchRegionId = null; typeAccount = null"
						>
							<template v-slot:prepend-item v-if="regions">
								<v-list-item ripple @click="toggleReignsFilterItems">
									<v-list-item-action>
										<v-icon>
											{{ icon }}
										</v-icon>
									</v-list-item-action>
									
									<v-list-item-content>
										<v-list-item-title>
											Select All
										</v-list-item-title>
									</v-list-item-content>
								</v-list-item>
								<v-divider class="mt-2"></v-divider>
							</template>
							<template v-slot:selection="{ item, index }">
								<span v-if="index === 0" class="me-1">{{ item.name }}</span>
								<span
									v-if="index === 1"
									class="grey--text text-caption"
								>
									(+{{ regionIds.length - 1 }})
								</span>
							</template>
						</v-autocomplete>
					</v-col>
				</v-row>
			</v-col>

			<!-- type -->
            <v-col
            	cols="12"
            	md="2"
            	sm="3"
            	align-self="center"
            	class="px-1 pb-0"
            >
                <v-autocomplete
                    v-model="typeAccount"
                    :items="accountType"
                    item-text="name"
                    item-value="accountId"
                    :label="$t('inputs.type')"
                    outlined
                    dense
                    hide-details="auto"
                    @change="getName()"
                    :loading="loading"
                    :disabled="submitLoading || loading"
                    prepend-icon="mdi-account-tie"
                />
            </v-col>

			<!-- type account -->
			<v-col v-if="typeAccount === accountTypeValue.customer" cols="12" sm="3" md="2" class="px-1 pb-0">
				<v-autocomplete 
					v-model="type"
					:items="entityTypeArray"
					item-text="name"
					item-value="id"
					:label="$t('inputs.type')"
					outlined
					hide-details
					dense
					:disabled="loading || submitLoading"
					:loading="loading"
					@change="fetchAccountCustomerByType()"
				/>
			</v-col>

			<!-- account -->
			<v-col 
				cols="12" 
				lg="2" 
				md="3" 
				sm="4" 
				align-self="center" 
                class="px-1 pb-0"
			>
                <v-autocomplete
                    v-model="name"
                    :items="typeAccount !== null ? typeAccount !== accountTypeValue.customer ? names : customers : []"
                    item-text="name"
                    item-value="accountId"
                    :label="$t('inputs.name')"
                    outlined
                    dense
                    hide-details
                    :loading="loading || submitLoading"
                    :disabled="submitLoading || loading"
               />
            </v-col>  

			<!-- materials -->
			<v-col cols="12" sm="3"  md="2"  class="px-1 pb-0">
				<v-autocomplete 
					v-model="materialId"
					:items="materials"
					item-text="name"
					item-value="id"
					:label="$t('inputs.item')"
					outlined
					hide-details
					dense
					:disabled="loading || submitLoading"
					:loading="loading"
				/>
			</v-col>    

			<!-- classifications -->
			<v-col cols="12" sm="3" md="2" class="px-1 pb-0">
				<v-autocomplete
					label="Workload"
					v-model="workloadIds"
					:items="classification"
					item-text="name"
					item-value="id"
					clearable
					outlined
					dense
					hide-details="auto"
					multiple
					:disabled="loading || submitLoading"
					:loading="loading"
				></v-autocomplete>
			</v-col>
			<v-spacer />
			<v-col cols="12" sm="3" md="2" class="px-1 pb-0">
				<!-- date -->
				<bee-date-picker
					v-model="fromDate" 
					input-label="From Date"
					elevation="2"
					menu-picker
					dense
					hide-details
					:input-props="{
						outlined: true,
						dense: true,
						loading: submitLoading || loading,
						prependIcon: 'mdi-calendar'
					}"
					min="1940-01-01"
					:disabled="loading || submitLoading"
				/>
			</v-col>

			<!-- to date -->
			<v-col cols="12" sm="3" md="2" class="px-1 pb-0"> 
				<!-- date -->
				<bee-date-picker
					v-model="toDate" 
					input-label="To Date"
					elevation="2"
					menu-picker
					dense
					hide-details
					:input-props="{
						outlined: true,
						dense: true,
						loading: submitLoading || loading
					}"
					min="1940-01-01"
					:disabled="loading || Boolean($route.query.preview) || submitLoading"
				/>
			</v-col>

			<v-col class="d-flex py-0" align-self="center" >
                <v-btn
                    v-if="excelFile"
                    small
                    color="green lighten-1 white--text"
                    @click="importExcel"
                    class="mx-1"
                    :loading="loadingExcel"
                    :disabled="loadingExcel"
                >
                    <v-icon> mdi-file-table-outline </v-icon>
                    Export Excel
                </v-btn>
            </v-col>

			<!-- actions -->
			<v-spacer />
			<v-col cols="auto" class="px-0 px-md-3 pb-0">
				<v-btn
					color="success"
					class="px-sm-2 mx-2"
					:loading="loading"
					small
					:disabled="loading || (materialId === null && !name)"
					@click="isSearch = true; getStatistics()"
				>
					<v-icon> mdi-magnify </v-icon>
					{{ $t('search') }}
				</v-btn>

				<v-btn
					color="alert-color white--text"
					class="px-sm-4"
					:disabled="loading || !isSearch"
					small
					@click="reset()"
				>
					{{ $t('cancel') }}
				</v-btn>
			</v-col>
		</v-row>
		
		<bee-handy-table
			v-if="isSearch"
			:items="items"
			:headers="headers"
			class="mt-4"
			dense
			:items-per-page="-1"
			zebra
			:height="$vuetify.breakpoint.height - 210"
			hide-default-footer
		></bee-handy-table>
		<div
			v-if="!isSearch"
			class="d-flex flex-column justify-center align-center"
			:style="{ height: `${$vuetify.breakpoint.height - 210}px` }"
		>
			<template>
				<v-icon size="60" class="mb-1">mdi-invoice-list</v-icon>
				<span class="text-h6">{{$t('WarehousesStatistics.please-select-a-filter')}}</span>
			</template>
		</div>
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import moment from 'moment'
import { entityTypeArray, accountType, accountTypeValue } from '@/helpers/enums';
import { managementHierarchyRegions, excelFile } from '@/configs/global.config'

export default {
	data () {
		return {
			// filter
			cityId: [],
			regionIds: [],
			fromDate: null,
			toDate: null,
			type: 1,
			materialId: null,
			workloadIds: [],
			searchCityId: null,
			searchRegionId: null,
			accounts: [],

			loading: false,
			submitLoading: false,
			regionsLoading: false,

			items: [],
			staff: [],
			customers: [],
			entityTypeArray,
			isSearch: false,
			tab: 0,
			managementHierarchyRegions,
			accountType,
			typeAccount: null,
			names: [],
			item: null,
			excelFile,
			name: null,
			accountTypeValue
		}
	},

	computed: {
		...mapState({
			cities: state => state.cities.cities,
			regionsAll: state => state.regions.regionsAll,
			regions: state => state.regions.regions,
			materials: state => state.materials.materials,
			classification: state => state.workload.classification
		}),

		...mapGetters({
			getMaterialById: 'materials/getMaterialById'
		}),

		isAllFieldsEmpty(){
            return !this.regionIds.length === 0 && !this.fromDate && !this.toDate && !this.type && !this.name
        },

		iconCities () {
            if (this.selectedAllCities) return 'mdi-close-box'
            return 'mdi-checkbox-blank-outline'
        },

		selectedAllCities () {
            return this.cityId.length === this.cities.length;
        },
	
		selectedAllRegions () {
            return this.regionIds.length === this.regionsAll.filter(c => this.cityId?.includes(c.cityId)).length;
        },

		icon () {
            if (this.selectedAllRegions) return 'mdi-close-box'
            return 'mdi-checkbox-blank-outline'
        },

		headers () {
			const headers = [
				{
					text: 'المادة',
					name: 'materials',
					value: 'materialId',
					setValueExpr: val => this.getMaterialById(val)?.name
				},
				{
					text: 'الحساب',
					name: 'account',
					value: 'accountId',
					setValueExpr: val => this.customers.find(c => c.accountId === val)?.name
				},
				{
					text: 'الكمية الداخلة',
					name: 'in',
					value: 'totalIn'
				},
				// {
				// 	text: 'الكمية الخارجة',
				// 	name: 'out',
				// 	value: 'totalOut'
				// },
				{
					text: 'تاريخ آخر مناقلة',
					name: 'lastCreatedAt',
					value: 'lastCreatedAt',
					setValueExpr: val => moment(val).format('YYYY-MM-DD')
				},

				{
					text: 'مستلم من ',
					name: 'fromAccountIds',
					value: 'fromAccountIds',
					setValueExpr: val => val.map(c => this.staff.find(s => s.accountId === c)?.name).join(', ')
				}
			]
			return headers
		}	
	},

	methods: {
		moment,
		toggleCities () {
            this.$nextTick(() => {
                if (this.selectedAllCities) {
                    this.cityId = []
                    this.regionIds = []
                } 
                else {
                    this.cityId = this.cities.map(c => c.id);
                    this.setRegions()
                }
            })
        },

		setRegions () {
            this.regionIds = [];
            if (!this.cityId) return;
            this.regionsAll.forEach(region => {
                if (this.cityId.includes(region.cityId)) {
                    this.regionIds.push(region.id);
                }
            });
        },

		toggleReignsFilterItems () {
            this.$nextTick(() => {
                if (this.selectedAllRegions) {
                    this.regionIds = []
                } else {
                    this.regionIds = this.regionsAll.filter(c => this.cityId.includes(c.cityId)).map(c => c.id);
                }
            })
        },

		getStatistics() {
			this.loading = true
			this.$store.dispatch('statistics/fetchStatisticsWarehouse', {
				regionIds: [],
				materialId: this.materialId, 
				fromDate: this.fromDate, 
				toDate: this.toDate,
				accountId: this.name,
				workloadIds: this.workloadIds,
				types: [this.type]
			}).then((data) => {
				this.items = data
			}).finally(() => {
				this.loading = false
			})
		},

		fetchAccountCustomerByType () {
			// if (this.tab === 1) {
			this.submitLoading = true
			this.$store.dispatch('accounts/fetchAllBrief', { type: 1, entityType: this.type, regionIds: [] }).then((data) => {
				this.customers = data
			}).finally(() => {
				this.submitLoading = false
			})
			// }
		},

		reset () {
			this.cityId = []
			this.regionIds = []
			this.fromDate = null
			this.toDate = null
			this.type = 1
			this.name = null
			this.materialId = null
			this.isSearch = false
			this.workloadId = null
		},

		getName() {
			if (this.typeAccount !== accountTypeValue.customer) {
				this.submitLoading = true
				this.$store.dispatch('accounts/fetchAllBrief', { type: this.typeAccount, regionIds: this.regionIds.length ? this.regionIds : [] }).then((data) => {
					// console.log(data)
					this.names = data
					this.submitLoading = false
				}).finally(() => {
					this.submitLoading = false
				})
			}
        },
	},

	created () {
		this.loading = true
		Promise.all([
			!this.cities.length ? this.$store.dispatch('cities/fetchCityMine', {}) : null,
			!this.regionsAll.length ? this.$store.dispatch('regions/fetchAllRegions', {}) : null,
			!this.materials.length ? this.$store.dispatch('materials/fetchAll') : null,
			!this.classification.length ? this.$store.dispatch('workload/fetchAll') : null,
			this.$store.dispatch('accounts/fetchAllBrief', { type: 0, regionIds: [] }).then((data) => {
				this.staff = data
			}),
			this.$store.dispatch('accounts/fetchAllBrief', { type: 1, entityType: 1, regionIds: [] }).then((data) => {
				this.customers = data
			})
		]).finally(() => {
			this.loading = false
		})
	}	
}
</script>
