export const logo = require('@/assets/brands/OUBARI.png');
export const drawerLogos = {
    normal: require('@/assets/brands/Untitled-1.png'),
    mini: require('@/assets/brands/Untitled-1.png')
};
// '@/assets/logo-eng (2).png'
// '@/assets/brands/ibn-alhaythem.png'
// @/assets/brands/ibn-alhaythem-mini.png

// '@/assets/brands/OUBARI.png'
// @/assets/brands/Untitled-1.png
// @/assets/brands/Untitled-1.png
export const colors = {
    // primary:  '#253c99',  
    // #0b5b6f //0b5b6f
    primary: '#253c99',  
    // '#', // #253c99  #014a97  #  041943 
    primaryLight: '#b2c1ff',
    //  '#ccddfd', // #b2c1ff #c0dfff # a0ecff
    // secondary: '#5371ad', #85ba00 // #f57f20 // #f57f20 #5371ad
    secondary: '#f57f20',
    lightBlue: '#0996b9', // #0996b9 # # 0996b9 85b901 103aa2
}
